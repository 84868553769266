import React, { useEffect } from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { useGlobalContext } from "../context/context"
import { setLocalStorage } from "../localStorageService/localStorageService"
import { checkSessionToken } from "../utils/checkSessionToken"
import Seo from "../components/seo"
import Header from "../components/regions/header"
import Layout from "../components/layout"
import Container from "../components/block/container"
import FieldText from "../components/field/fieldText"
import FieldButton from "../components/field/fieldButton"

const CancelPage = () => {
  const { setUserInfo, userInfo } = useGlobalContext()

  useEffect(() => {
    setUserInfo({
      ...userInfo,
      features_package: "",
    })
    setLocalStorage("sideline-client-info", {
      ...userInfo,
      features_package: "",
    })

    if (!checkSessionToken()) {
      navigate("/")
    }
  }, [])

  return (
    <>
      <Header />
      <Layout>
        <Seo title="Canceled payment" pageClass="page--cancel" />
        {!checkSessionToken() ? (
          <>
            <Container classes="block--page-top-content block--page-top-content--cancel"></Container>
          </>
        ) : (
          <>
            <Container classes="block--page-top-content block--page-top-content--cancel">
              <FieldText>
                <h1 className="text text--centered">
                  Your payment was canceled
                </h1>
                <p className="text text--centered">
                  You canceled your payment or something went wrong, click the
                  button below to try again
                </p>
              </FieldText>

              <FieldButton>
                <Link
                  className="button button--big button--red"
                  to="http://sideline-marketing.docksal/sign-up"
                >
                  Click here to try again
                </Link>
              </FieldButton>
            </Container>
          </>
        )}
      </Layout>
    </>
  )
}

export default CancelPage
